@font-face {
  font-family: "Bitter";
  src: url("/assets/fonts/Bitter-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("/assets/fonts/Raleway-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Raleway-Italic";
  src: url("/assets/fonts/Raleway-Italic-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Raleway-SemiBold";
  src: url("/assets/fonts/Raleway-SemiBold.ttf");
}

i {
    font-family: "Raleway-Italic";
}

b {
    font-family: "Raleway-SemiBold";
}

strong {
    font-family: "Raleway-SemiBold";
}

.quote{
	/*font-style: italic;*/
  font-family: "Raleway-Italic";
	margin: 0 0 0.5ex 0;
}

.quoted {
	font-size: 14px;
	/*font-weight: 700 !important;*/
  font-family: "Raleway-SemiBold";

}

body {
  margin: 60px auto;
  font-family: 'Raleway', sans-serif;
	background-color: #ffffff;
	color: #000000;

  width: 580px;
  font-size: 18px;
  line-height: 26px;

  font-display: block;

}

main {
	clear: both;
}

header {
  margin-top: 30px;
  margin-bottom: 20px;
}

header a {
	color: #484848;
	text-decoration: none;
	border-bottom: 1px dotted #999892;
}

header a:visited {
	color: #484848;
}

footer {
  border-top: 1px solid rgb(206,206,206);

  margin-top: 48px;
  padding-top: 24px;

  font-size: 14px;
}

header a:hover {
	border-bottom: 1px solid #999892;
}

h1 {
  font-size: 38px;
  line-height: 42px;
  font-weight: normal;
  margin-bottom: 20px;
  font-family: 'Bitter', serif;
}

body.post h1 {
  margin-bottom: 5px;
}

body.post header p {
  font-size: 14px;
}

h2 {
  margin-top: 40px;
  margin-bottom: 5px;
  font-family: 'Raleway-SemiBold';
  font-size: 24px;
/*  font-weight: 700;*/
}

h3 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: "Raleway-SemiBold";
  font-size: 20px;
  /*font-weight: bold;*/
}

img.profile {
  background-size: 100%;
  display: block;
  height: 100px;
  overflow: hidden;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  width: 100px;
  margin-right: 15px;
  float: left;
}


pre {
  font-size: 15px;
  white-space: pre;
  word-wrap: normal;
  overflow: auto;
  width: auto;
}

@media screen and (max-width: 580px) {
  body {
    margin: 10px auto;
    padding: 0 20px 0 20px;
    width: auto;
    word-wrap: break-word;
  }

  pre {
    font-size: 14px;
    line-height: 16px;
  }

  img {
    max-width: 100%;
    width: auto;
    height: auto;

  }

  img.profile {
    height: 110px;
    width: 110px;
    float: left;
  }

  .video-wrap {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
  }

  .video-wrap iframe,
  .video-wrap object,
  .video-wrap embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
}


a {
  color:  #000000;
  font-family: "Raleway-SemiBold";
}

a:hover {
    color: black;
	text-decoration: none;
}

.cat {
  color: #0e56de;
}

p {
  margin: 0 0 2ex 0;
}

ul {
  margin: 0 0 2ex 0;
  padding: 0;
}

li {
  margin: 0 0 0 20px;
  padding: 0;
}

.cover-left {
  width: 49%; 
  float: left;
  padding-right: 2%;
}

.cover-right {
  width: 49%; 
  float: left; 
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

.copyright {
  float: right;
}

.related-description {
  font-size: 0.83em;
}

.related-title {
  
  margin-block-end: 0em;
}

.lead-image {
  padding: 0 0 2ex 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image-credit {
  font-style: italic;
  font-size: smaller;
  text-align: center;

}

.social-div {
    display: flex;
    align-items: center;
}

#li-social {
    margin-left: 5px;
    margin-right: 5px;
}

.tweet-me {
  font-size: smaller;
  font-family: "Raleway-Italic";
}

table, th, td {
  border: 2px solid black;
  border-collapse: collapse;
  padding: 10px;
  text-align: left;
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr { 
    display: block; 
  }

  table {
    width: 100%;
  }
  
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  th {display: none; }
  
  tr { border: 1px solid #ccc; }
  
  td { 
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee; 
    position: relative;
    padding-left: 50%; 
  }
  
  td:before { 
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%; 
    padding-right: 10px;
    font-weight: bold; 
  }
  
  /*
  Label the data relating to a table in Organisational Abilities book
  */
  td:nth-of-type(1):before { content: "Common needs of managers:"; }
  td:nth-of-type(2):before { content: "We will look at how to:"; }
  td:nth-of-type(3):before { content: "Ability to:"; }
}